@media all and (min-width: 480px) {
    .Register {
      /* padding: 60px 0; */
    }
  
    .Register form {
      margin: 0 auto;
    }

    .RequiredField {
      color: red;
    }
    
    .lengthWarning {
      color: grey;
      font-size: 12px;
    }
  
  }

