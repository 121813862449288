.top-header {
  padding-top: 15px;
  padding-bottom: 15px;
}

.container-fluid {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.bg-dark {
  background-color: #003e68 !important;
}

.nav-link {
  color: #fff !important;
}

.content {
  padding:15px;
}

.nav-link {
  display: inline-block !important;
}

.app-full-height {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.app .content-col {
  padding-top: 10px;
  padding-bottom: 10px;
  overflow: auto;
}

.app .nav-menu {
  border-right: 1px solid #ccc;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.app .navbar-brand {
  font-weight: bold;
}

.media-body {
  padding: 0 10px;
}
